.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  transition: width 999999s linear 999999s;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  > .MuiCard-root,
  > * > .MuiCard-root {
    transition: box-shadow 0.1s, border-color 0.1s;
  }
  > .MuiCard-root {
    border: 1px solid transparent;
  }
}
.react-grid-item:hover:not(.disabled) {
  > .MuiCard-root,
  > * > .MuiCard-root {
    border: 1px solid #0288d1;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
  .MuiCard-root {
    border: 1px solid #0288d1;
  }
  > .react-resizable-handle::after {
    background-color: #0288d1;
  }
  .MuiBox-root,
  .react-grid-item {
    // prevent children from changing until group is finished resizing
    transition: width 999999s linear 999999s, transform 999999s linear 999999s;
  }
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
  > .MuiCard-root,
  > * > .MuiCard-root {
    transition: transform 0.2s ease;
    transform: rotate(3.5deg);
    border: 1px solid #0288d1;
  }
}

.react-grid-item.react-grid-placeholder {
  border-radius: 4px;
  background: none;
  opacity: 0.8;
  border: 2px dashed #a7a7a7;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  z-index: 0;
  > .react-resizable-handle::after {
    background-color: transparent !important;
  }
}

.react-grid-item {
  & > .react-resizable-handle {
    display: block;
    z-index: 300;
    width: 16px;
    height: 16px;
    padding: 0;
    background-image: none;
    bottom: 0;
    right: 0;
    position: absolute;
    cursor: se-resize;
    margin-right: -3px;
    margin-bottom: -3px;
  }

  &.disabled > .react-resizable-handle {
    z-index: 200;
    margin-right: -10px;
    margin-bottom: -10px;
  }
}

.react-grid-item > .react-resizable-handle::after {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  transform: translate(50%, 50%);
  border: none;
  left: -4px;
  top: -5px;
  bottom: initial;
  right: initial;
  content: '';
  position: absolute;
  cursor: se-resize;
}

.react-grid-item:hover:not(.disabled) > .react-resizable-handle::after {
  background-color: #0288d1;
  cursor: se-resize;
}

.react-grid-item.react-draggable-dragging:not(.disabled)
  > .react-resizable-handle::after {
  background-color: transparent;
}

.react-grid-item {
  .drag-handle,
  .group-drag-handle {
    color: transparent;
    transition: color 0.2s;
  }
  .view-button {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.react-grid-item:hover {
  .drag-handle:not(.disabled),
  .group-drag-handle:not(.disabled) {
    color: rgba(0, 0, 0, 0.54);
  }
  .drag-handle:hover:not(.disabled),
  .group-drag-handle:hover:not(.disabled) {
    color: #0288d1;
  }
  .view-button:not(.disabled) {
    opacity: 1;
  }
}

.react-grid-item .drag-handle:active,
.react-grid-item .group-drag-handle:active {
  color: #0288d1; /* Maintains blue on click */
}

.react-grid-item.react-draggable-dragging .drag-handle,
.react-grid-item.react-draggable-dragging:not(.disabled)
  .group-drag-handle:not(.disabled) {
  color: #0288d1; /* Maintains blue while dragging */
}

.react-grid-item.resizing:not(.disabled) > * > .react-grid-item {
  background-color: #0288d1; /* Maintains blue while dragging */
}
